import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import Sticky from 'react-stickynode';
import { find } from 'lodash';
import moment from 'moment';
import scrollTo from '../helpers/scrollTo';
import { Link } from 'gatsby';
import { ScrollingProvider, SectionLink, Section } from 'react-scroll-section';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import DataBlogCategories from '../data/blogCategories.json';

import Layout from '../components/Layout';
import VideoModalPreview from '../components/VideoModalPreview';

import imgAuthor from '../img/svg/pageSolutions/author.svg';
import calendar from '../img/svg/pageSolutions/calendar.svg';
import duration_ico from '../img/svg/pageSolutions/duration_ico.svg';
import free_bg from '../img/svg/pageSolutions/free_bg.png';
import green_lines from '../img/svg/pageSolutions/green_lines.svg';
import green_lines_small from '../img/svg/pageSolutions/green_lines_small.svg';
import green_lines2 from '../img/svg/pageSolutions/green_lines2.svg';
import green_lines3 from '../img/svg/pageSolutions/green_lines3.svg';
import how_dotted from '../img/svg/pageSolutions/how_dotted.svg';
import learning_bg from '../img/svg/pageSolutions/learning_bg.svg';
import opinion_bottom from '../img/svg/pageSolutions/opinion_bottom.svg';
import opinion_top from '../img/svg/pageSolutions/opinion_top.png';
import our_shape from '../img/svg/pageSolutions/our_shape.svg';
import polygon_green from '../img/svg/pageSolutions/polygon_green.svg';
import polyline1 from '../img/svg/pageSolutions/polyline1.svg';
import polyline2 from '../img/svg/pageSolutions/polyline2.svg';
import polyline3 from '../img/svg/pageSolutions/polyline3.svg';
import schema1 from '../img/svg/pageSolutions/schema1.png';
import schema2 from '../img/svg/pageSolutions/schema2.png';
import schema3 from '../img/svg/pageSolutions/schema3.png';
import schema_bg_left from '../img/svg/pageSolutions/schema_bg_left.svg';
import schema_bg_right from '../img/svg/pageSolutions/schema_bg_right.svg';
import time_ico from '../img/svg/pageSolutions/time_ico.svg';
import waves_grey from '../img/svg/pageSolutions/waves_grey.svg';
import arrow_blue from '../img/svg/pageSolutions/arrow_blue.svg';
import arrow_blue_r from '../img/svg/pageSolutions/arrow_blue_r.svg';
import arrow_more from '../img/svg/pageSolutions/arrow_more.svg';
import mlops_dialog from '../../static/img/mlops_video_cover.jpg';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

function formatDate(date) {
  return !isNaN(new Date(date)) ? moment(date).format('D MMMM YYYY') : null;
}

export const SolutionsPageTemplate = (props) => {
  let blogsFiltered;
  const { blogs } = props;
  const offsetNav = 100;
  const offsetHeader = 85

  blogsFiltered = blogs.reduce((prevVal, currentValue) => {
    let text = 'MLOps';
    let isIncludeTag = find(currentValue.frontmatter.tags, (obj) => obj?.label?.toLowerCase() == text.toLowerCase());
    if (isIncludeTag) {
      return [...prevVal, {
        ...currentValue.frontmatter,
        ...{ excerpt: currentValue.excerpt },
      }]
    }

    return prevVal
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.replace('#', '');
      scrollTo(hash, 'smooth', (-offsetNav - offsetHeader));
    }
  }, []);

  return (
    <ScrollingProvider offset={-offsetNav - offsetHeader}>
      <div className='SolutionsNew bg-white'>
        <Section section="start" className="flex flex-col relative pb-5 sm:pb-0">
          <img src={polyline1} alt="" width="425" height="478" loading="lazy" decoding="async" className='absolute -left-24 top-1/2 -mt-96 z-0' />
          <img src={polyline2} alt="" width="525" height="591" loading="lazy" decoding="async" className='absolute -right-16 top-1/2 -mt-80 z-0' />
          <div className="container mx-auto grow flex flex-col justify-between">
            <div className="grid sm:grid-cols-2 md:gap-8 my-auto items-center sm:py-32 relative">
              <div className="text-lg mb-12 sm:mb-0 relative sm:pr-4 mt-12 sm:mt-0">
                <h1 className="text-4xl xs:text-5xl xs:leading-normal font-bold text-secondary mb-3">
                  {props.hero.title}
                </h1>
                <h2 className="text-xl sm:text-2xl leading-normal mb-12">{props.hero.subtitle}</h2>

                <ul className="mb-5">
                  {props.hero.items.map((item, i) => (
                    <li className="SolutionsNew__ul__bullets__li mb-3">{item.text}</li>
                  ))}
                </ul>
                <p className="font-bold">
                  {props.hero.subtext}
                </p>
              </div>
              <div className="relative flex justify-center">
                <img src={green_lines} width="257" height="257" alt="" className='absolute z-0 bottom-7 -left-16 hidden xs:block' />
                <div className=" relative -mx-4 xs:-mx-0">
                  <VideoModalPreview
                    className=""
                    videoID={'Z8O3C1Q-B7E'}
                    channel={'youtube'}
                    // url_mp4={{ publicURL: '/img/big-data-technology-summit-2020.mp4' }}
                    // url_webm={{ publicURL: '/img/big-data-technology-summit-2020.webm' }}
                    image={mlops_dialog}
                  />
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Sticky top={offsetNav} enabled={true} innerZ={50}>
          <section id="solution-mlops-navbar" className="bg-white">
            <div className="py-5 sm:py-6 overflow-auto px-4 justify-end bottom-0 left-0 right-0 z-10">
              <div className="flex md:justify-center">
                <SectionLink section="SolutionsNew__challenge">
                  {({ onClick, isSelected }) => (
                    <div onClick={onClick} className={`cursor-pointer font-bold toLink text-secondary border-2 border-secondary hover:bg-secondary active:bg-secondary-lightest active:border-secondary-lightest hover:text-white rounded px-4 py-2 leading-6 mx-3 whitespace-nowrap ${isSelected ? 'bg-secondary text-white' : ''}`}>ML Challenges</div>
                  )}
                </SectionLink>
                <SectionLink section="SolutionsNew__what">
                  {({ onClick, isSelected }) => (
                    <div onClick={onClick} className={`cursor-pointer font-bold toLink text-secondary border-2 border-secondary hover:bg-secondary active:bg-secondary-lightest active:border-secondary-lightest hover:text-white rounded px-4 py-2 leading-6 mx-3 whitespace-nowrap ${isSelected ? 'bg-secondary text-white' : ''}`}>What is MLOps</div>
                  )}
                </SectionLink>
                <SectionLink section="SolutionsNew__how">
                  {({ onClick, isSelected }) => (
                    <div onClick={onClick} className={`cursor-pointer font-bold toLink text-secondary border-2 border-secondary hover:bg-secondary active:bg-secondary-lightest active:border-secondary-lightest hover:text-white rounded px-4 py-2 leading-6 mx-3 whitespace-nowrap ${isSelected ? 'bg-secondary text-white' : ''}`}>How YOU® MLOps Platform works</div>
                  )}
                </SectionLink>
                <SectionLink section="SolutionsNew__benefits">
                  {({ onClick, isSelected }) => (
                    <div onClick={onClick} className={`cursor-pointer font-bold toLink text-secondary border-2 border-secondary hover:bg-secondary active:bg-secondary-lightest active:border-secondary-lightest hover:text-white rounded px-4 py-2 leading-6 mx-3 whitespace-nowrap ${isSelected ? 'bg-secondary text-white' : ''}`}>Benefits</div>
                  )}
                </SectionLink>
                <SectionLink section="SolutionsNew__timeline">
                  {({ onClick, isSelected }) => (
                    <div onClick={onClick} className={`cursor-pointer font-bold toLink text-secondary border-2 border-secondary hover:bg-secondary active:bg-secondary-lightest active:border-secondary-lightest hover:text-white rounded px-4 py-2 leading-6 mx-3 whitespace-nowrap ${isSelected ? 'bg-secondary text-white' : ''}`}>Project timeline</div>
                  )}
                </SectionLink>
                <SectionLink section="SolutionsNew__base">
                  {({ onClick, isSelected }) => (
                    <div onClick={onClick} className={`cursor-pointer font-bold toLink text-secondary border-2 border-secondary hover:bg-secondary active:bg-secondary-lightest active:border-secondary-lightest hover:text-white rounded px-4 py-2 leading-6 mx-3 whitespace-nowrap mr-8 ${isSelected ? 'bg-secondary text-white' : ''}`}>MLOps Knowledge Base</div>
                  )}
                </SectionLink>
              </div>
            </div>
          </section>
        </Sticky>
        <Section id="start2" className="bg-background-blue relative">
          <img src={our_shape} alt="" width="696" height="476" loading="lazy" decoding="async" className='absolute left-0 bottom-0 z-0' />
          <div className="relative overflow-hidden px-4 pt-12 z-10">
            <div className="SolutionsNew__our__columns_left">
              <h2 className="text-3xl xs:text-5xl leading-tight xs:leading-tight font-bold text-secondary">
                <ReactMarkdown
                  children={props.clients.title}
                  rehypePlugins={[rehypeRaw]}
                />
              </h2>
            </div>
            <div className="SolutionsNew__our__columns_right">
              <Swiper
                modules={[Navigation]}
                spaceBetween={8}
                navigation={true}
                breakpoints={{
                  220: {
                    width: 290
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                }}
                className="SolutionsNew__our__columns_right_in flex items-start md:items-stretch pt-12 pb-20 z-10 relative">
                {props.clients.items.map((item, i) => (
                  <SwiperSlide className="swiper__our__item bg-white mx-2 sm:mx-4 px-6 py-8 w-72 shrink-0 rounded-lg drop-shadow-big flex flex-col">
                    <div className='grow'>
                      <div className='mb-4 sm:text-right'><img src={item.image?.publicURL} alt="" className="w-auto h-5 sm:h-8 inline" /></div>
                      <div className="mb-10 sm:text-3.5xl sm:leading-tight font-bold">
                        {item.title}
                      </div>
                      <div className="text-sm mb-12">
                        {item.text}
                      </div>
                    </div>
                    {item.btnLink && item.btnText &&
                      <a href={item.btnLink} className='font-bold text-xs text-white py-3 px-6 block text-center bg-primary hover:bg-primary-darken active:bg-primary-lighten rounded uppercase'>
                        {item.btnText}
                      </a>
                    }
                  </SwiperSlide>
                ))}
              </Swiper>
              <img src={green_lines_small} width="117" height="129" alt="" className='absolute -z-10 bottom-14 left-16 md:hidden' />
            </div>
          </div>
        </Section>
        <Section className="bg-white py-24 sm:py-32 relative" id="SolutionsNew__challenge">
          <img src={learning_bg} alt="" width="566" height="620" loading="lazy" decoding="async" className='hidden md:block SolutionsNew__learning_bg_l' />
          <img src={learning_bg} alt="" width="566" height="620" loading="lazy" decoding="async" className='hidden md:block SolutionsNew__learning_bg_r' />
          <div className="container mx-auto relative z-10">
            <div className="text-center max-w-4xl mx-auto">
              <h2 className="text-3xl xs:text-5xl mb-12 leading-tight xs:leading-tight font-bold text-secondary">{props.challenge.title}</h2>
              <p className="mb-6 text-lg sm:text-3.5xl sm:leading-tight text-primary-darken font-bold">
                {props.challenge.subtitle}
              </p>
              <p className="mb-12 sm:mb-14 md:mb-20">
                {props.challenge.text}
              </p>
            </div>
            <div className="xs:grid xs:grid-cols-3 gap-x-4 sm:gap-x-8 sm:pt-2">
              {props.challenge?.items.map((item, i) => (
                <div className='SolutionsNew__learning__block mb-4 xs:mb-0'>
                  <div className="flex xs:block py-4 xs:px-4 sm:p-12 rounded-lg xs:drop-shadow-big relative bg-white z-10 xs:min-h-full">
                    <div className='mr-4 w-14 h-14 sm:w-24 sm:h-24 xs:mb-4 sm:mb-12 shrink-0 rounded-full border border-secondary2-lighten bg-accent1-lightest/50 relative'>
                      <img src={item.image?.publicURL} alt="" className="absolute m-auto w-8 sm:w-14 -inset-0" />
                    </div>
                    <div>
                      <div className="min-h-[60px] sm:min-h-[120px] mb-3 sm:mb-12 flex flex-col justify-center">
                        <h3 className="text-lg sm:text-3.5xl sm:leading-tight font-bold text-secondary">
                          {item.title}
                        </h3>
                      </div>
                      <p className="text-greyscale-lighten">
                        <ReactMarkdown
                          children={item.text}
                          rehypePlugins={[rehypeRaw]}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Section>
        <Section id="start3" className="bg-secondary py-14 relative">
          <img src={free_bg} alt="" width="450" height="450" loading="lazy" decoding="async" className='absolute -top-full -bottom-full my-auto SolutionsNew__flip__h max-w-none md:w-[750px] -left-56 xs:-left-44 sm:-left-24 md:-left-32 z-0' />
          <img src={free_bg} alt="" width="450" height="450" loading="lazy" decoding="async" className='absolute -top-full -bottom-full my-auto max-w-none md:w-[750px] -right-56 xs:-right-44 sm:-right-24 md:-right-32 z-0' />
          <div className="container mx-auto relative z-10">
            <div className="text-center leading-relaxed max-w-xs xs:max-w-none mx-auto">
              <p className="mb-2 text-white sm:text-2xl">
                {props.ctaA.subtitle}
              </p>
              <h2 className="text-white text-lg sm:text-3xl font-bold mb-9"> {props.ctaA.title}</h2>
              <div className="text-center">
                <div id="solution_mlops_schedule_1_btn" onClick={() => scrollTo('contact')} className="cursor-pointer w-full xxs:w-auto inline-block bg-accent2 hover:bg-error active:bg-error-500 rounded uppercase font-bold py-4 px-4 xxs:px-12 text-white text-xs sm:text-base">{props.ctaA.btnText}</div>
              </div>
            </div>
          </div>
        </Section>
        <Section className="relative z-10" id="SolutionsNew__what">
          <div className="container mx-auto">
            <div className="sm:grid grid-cols-2 gap-4 items-center pt-16 pb-14 sm:pt-24 sm:pb-28">
              <div className="text-center sm:text-left mb-12 sm:mb-0">
                <h2 className="text-3xl xs:text-5xl mb-12 leading-tight xs:leading-tight font-bold text-secondary">
                  {props.mlops.intro.title}
                </h2>
                <p className="mb-6 text-greyscale-lighten font-normal">
                  {props.mlops.intro.text}
                </p>
                <p className="font-bold text-greyscale-lighten">
                  {props.mlops.intro.subtext}
                </p>
              </div>
              <div className="sm:mt-6">
                <GatsbyImage image={getImage(props.mlops.intro.image)} alt="" className="block mx-auto relative left-5 xxs:left-7" />
              </div>
            </div>
          </div>
        </Section>
        <section className="relative z-10 pb-16 xs:pb-36">
          <img src={polyline1} alt="" width="425" height="478" loading="lazy" decoding="async" className='absolute left-1/2 -ml-220 top-1/2 -mt-86 z-0' />
          <img src={polyline1} alt="" width="525" height="546" loading="lazy" decoding="async" className='absolute left-1/2 ml-96 top-full -mt-72 z-0' />
          <div className="container mx-auto xs:relative"><div className="bg-background-blue md:rounded-lg -mx-4 md:mx-auto px-4 md:pl-20 py-12 text-center xs:text-left xs:pr-96">
            <p className="hidden xs:block mb-4 text-secondary uppercase font-bold">
              {props.mlops.cta.subtitle}
            </p>
            <h2 className="text-3xl xs:text-3.5xl mb-12 leading-tight xs:leading-tight font-bold text-secondary">
              {props.mlops.cta.title}
            </h2>
            <div className="xs:h-0">
              <div className="inline-block relative xs:static mx-14">
                <img src={green_lines2} width="200" height="200" alt="" className='absolute z-0 top-1/2 -mt-24 -left-7 xs:left-auto xs:right-36 sm:right-14' />
                <GatsbyImage image={getImage(props.mlops.cta.image)} alt="" className="w-[329px] h-[451px] mx-auto block relative z-10 right-0 mb-7 xs:absolute xs:-top-96 xs:-bottom-96 xs:my-auto sm:right-32" />
              </div>
            </div>
            <div className="text-left">
              <a href={props.mlops.cta.btnLink} id="ebook_download" className="w-full xxs:w-auto inline-block font-bold text-xs xxs:text-base text-white py-3 px-12 text-center bg-primary hover:bg-primary-darken active:bg-primary-lighten rounded uppercase">
                {props.mlops.cta.btnText}
              </a>
            </div>
          </div></div>
        </section>

        <section className="z-30 mb-10 sm:mb-28 relative">
          <div className="container mx-auto">
            <div className="text-center mx-auto max-w-4xl">
              <h2 className="text-3xl xs:text-5xl mb-12 leading-tight xs:leading-tight font-bold text-secondary">
                {props.mlops.main.title}
              </h2>
              <p className="mb-8">
                <ReactMarkdown
                  children={props.mlops.main.textA}
                  rehypePlugins={[rehypeRaw]}
                />
              </p>
              <p className="mb-8">
                <ReactMarkdown
                  children={props.mlops.main.textB}
                  rehypePlugins={[rehypeRaw]}
                />
              </p>
              <p className="mb-12 sm:mb-16 font-bold text-primary-darken">
                {props.mlops.main.subText}
              </p>
              <div className="flex flex-wrap justify-center items-center xs:-mx-12">
                {props.mlops.main?.items.map((item, i) => (
                  <div className="mb-6 xs:mx-12 scale-60 xs:scale-100"><img src={item.image?.publicURL} alt="" /></div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Section id="start7">
          <img src={opinion_top} alt="" loading="lazy" width="1600" decoding="async" className='SolutionsNew__opinion__top' />
          <div className="bg-secondary -mt-1 py-4 sm:py-24 px-6 relative z-10">
            <img src={green_lines2} width="257" height="257" alt="" className="hidden md:block absolute z-0 -bottom-28 -right-32" />
            <div className="container mx-auto relative z-10">
              <div className="sm:grid grid-cols-3 items-center gap-4 max-w-5xl mx-auto">
                <div className="col-span-1 w-32 sm:w-52 mb-10 sm:mb-0 mx-auto">
                  <img src={props.mlops.opinion.image?.publicURL} alt="" />
                </div>
                <div className="col-span-2 text-white text-center sm:text-left text-lg sm:text-2xl leading-relaxed sm:leading-relaxed">
                  <p className="mb-6 italic">
                    {props.mlops.opinion.text}
                  </p>
                  <div className="text-primary mb-1">{props.mlops.opinion.authorName}</div>
                  <div className="font-bold text-base xs:text-lg xs:leading-relaxed">{props.mlops.opinion.authorTitle}</div>
                </div>
              </div>
            </div>
          </div>
          <img src={opinion_bottom} alt="" loading="lazy" width="1600" height="367" decoding="async" className='SolutionsNew__opinion__bottom bg-secondary' />
        </Section>
        <Section className="bg-background-blue py-6 -mt-1 relative z-10" id="SolutionsNew__how">
          <img src={schema_bg_left} alt="" loading="lazy" decoding="async" className='absolute left-0 top-0 hidden md:block' />
          <img src={schema_bg_right} alt="" loading="lazy" decoding="async" className='absolute right-0 top-0 hidden md:block' />
          <div className="container mx-auto">
            <div className="text-center mx-auto">
              <h2 className="text-3xl xs:text-5xl mb-4 sm:mb-6 leading-tight xs:leading-tight font-bold text-secondary">
                {props.mlops.graph.title}
              </h2>
              <p className="text-lg leading-normal mb-8 sm:mb-16">
                <ReactMarkdown
                  children={props.mlops.graph.text}
                  rehypePlugins={[rehypeRaw]}
                />
              </p>
            </div>
            <div className="xs:grid grid-cols-3 gap-8 sm:gap-[2.3%] text-center mb-2">
              <div className="bg-white/30 p-4 rounded-lg mb-6 flex flex-col justify-center">
                <ReactMarkdown
                  children={props.mlops.graph.colA.subtext}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
              <div className="bg-white/30 p-4 rounded-lg mb-6 flex flex-col justify-center">
                <ReactMarkdown
                  children={props.mlops.graph.colB.subtext}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
              <div className="bg-white/30 p-4 rounded-lg mb-6 flex flex-col justify-center">
                <ReactMarkdown
                  children={props.mlops.graph.colC.subtext}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </div>
            <div id="solutions-mlops-scheme-explain" className="SolutionsNew__schema__wrapper relative sm:grid grid-cols-3 gap-8 sm:gap-[2.3%] text-center">
              {/*<div className="SolutionsNew__schema__line1"></div>*/}
              <div className="bg-white py-6 sm:pb-20 sm:hover:pb-0 px-3 mb-8 overflow-hidden rounded-lg SolutionsNew__schema__col group/schema">
                <div className='grow'>
                  <div className="text-accent1-lighten text-5xl font-bold sm:text-primary2-darken group-hover/schema:text-accent1-lighten">1</div>
                  <div className="font-bold text-2xl mb-9 sm:mb-16 text-secondary">
                    {props.mlops.graph.colA.title}
                  </div>
                </div>
                <div className="sm:hidden sm:-mt-10 group-hover/schema:block">
                  <div className="mb-12 text-greyscale-lighten font-normal">
                    <ReactMarkdown
                      children={props.mlops.graph.colA.text}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </div>
                  <img src={schema1} alt="" loading="lazy" decoding="async" className='block px-7 w-full max-w-sm mx-auto' />
                </div>
                <StaticImage src={'../img/svg/pageSolutions/schema-full.png'} placeholder="blurred" alt="" className='SolutionsNew__schema__full SolutionsNew__schema__full--1 hidden sm:block' />
              </div>
              <div className="bg-white py-6 sm:pb-20 sm:hover:pb-0 px-3 mb-8 overflow-hidden rounded-lg SolutionsNew__schema__col group/schema">
                <div className='grow'>
                  <div className="text-accent1-lighten text-5xl font-bold sm:text-primary2-darken group-hover/schema:text-accent1-lighten">2</div>
                  <div className="font-bold text-2xl mb-9 sm:mb-16 text-secondary">
                    {props.mlops.graph.colB.title}
                  </div>
                </div>
                <div className="sm:hidden sm:-mt-10 group-hover/schema:block">
                  <div className="mb-12 text-greyscale-lighten font-normal">
                    <ReactMarkdown
                      children={props.mlops.graph.colB.text}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </div>
                  <img src={schema2} alt="" loading="lazy" decoding="async" className='block px-7 w-full max-w-sm sm:-mb-16 mx-auto' />
                </div>
                <StaticImage src={'../img/svg/pageSolutions/schema-full2.png'} placeholder="blurred" alt="" className='SolutionsNew__schema__full SolutionsNew__schema__full--2 mx-auto  hidden sm:block' />
              </div>
              <div className="bg-white py-6 sm:pb-20 sm:hover:pb-0 px-3 mb-8 overflow-hidden rounded-lg SolutionsNew__schema__col group/schema">
                <div className="grow">
                  <div className="text-accent1-lighten text-5xl font-bold sm:text-primary2-darken group-hover/schema:text-accent1-lighten">3</div>
                  <div className="font-bold text-2xl mb-9 sm:mb-16 text-secondary">
                    {props.mlops.graph.colC.title}
                  </div>
                </div>
                <div className="sm:hidden sm:-mt-10 group-hover/schema:block">
                  <div className="mb-12 text-greyscale-lighten font-normal">
                    <ReactMarkdown
                      children={props.mlops.graph.colC.text}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </div>
                  <img src={schema3} alt="" loading="lazy" decoding="async" className='block px-7 w-full max-w-sm mx-auto' />
                </div>
                <StaticImage src={'../img/svg/pageSolutions/schema-full3.png'} placeholder="blurred" alt="" loading="lazy" decoding="async" className='SolutionsNew__schema__full SolutionsNew__schema__full--3 float-right hidden sm:block' />
              </div>
            </div>
          </div>
        </Section>
        <Section id="start4" className="pb-24 sm:pb-40 ">
          <img src={waves_grey} alt="" loading="lazy" decoding="async" className="block w-full" />
          <div className="container mx-auto">
            <div className="xs:grid grid-cols-10 gap-8 pt-4">
              <div className="text-center xs:text-left col-span-4 md:col-span-3">
                <h2 className="text-3xl sm:text-5xl mb-4 sm:mb-6 leading-tight sm:leading-tight font-bold text-secondary">
                  {props.mlops.principles.title}
                </h2>
                <p className="text-lg mb-12">{props.mlops.principles.text}</p>
              </div>
              <div className="col-span-6 md:col-span-7 md:grid md:grid-cols-2 md:gap-x-8">
                {props.mlops?.principles?.items.map((item, i) => (
                  <div className={classNames('relative h-20 sm:h-28 flex items-center pl-10 sm:pl-20 mb-6', {
                    'mr-5 col-span-1 md:mr-0': i % 2 == 0,
                    'ml-5 md:ml-0 col-span-1 md:top-16': i % 2 !== 0
                  })}>
                    <div className="absolute left-0 z-20">
                      <img src={polygon_green} alt="" loading="lazy" width='81' decoding="async" className="relative SolutionsNew__principles__polygon z-0" />
                      <img src={item.image?.publicURL} alt="" loading="lazy" decoding="async" className="absolute -inset-32 m-auto z-10" />
                    </div>
                    <div className="flex flex-col justify-center z-10 pr-[1.75rem] SolutionsNew__principle__bg__text">
                      <div className="relative z-20 pl-10 ml-2.5 xs:text-lg"><span className="relative z-10">{item.text}</span></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Section>
        <Section id="start5" className="bg-white ">
          <div className="container mx-auto">
            <div className="text-center max-w-4xl mx-auto">
              <h2 className="text-2xl sm:text-3xl mb-16 sm:mb-24 leading-tight sm:leading-tight font-bold text-secondary">
                {props.mlops.platform.title}
              </h2>
            </div>
            <div className="xs:grid grid-cols-7 gap-4 sm:gap-12 md:gap-24 pb-16 sm:pb-32 items-start">
              <div className='SolutionsNew__learning__block col-span-3 sm:col-span-4 SolutionsNew__learning__block--sm_visible -mx-4 xs:mx-0 mb-16 xs:mb-0'><div className="xs:rounded-lg overflow-hidden drop-shadow-big relative z-10">
                <StaticImage placeholder="blurred" src={'../img/svg/pageSolutions/about1.jpg'} alt="" className="block" />
              </div></div>
              <div className="col-span-4 sm:col-span-3">
                <p className="font-bold text-lg sm:text-xl mb-6 sm:mb-10">
                  {props.mlops.platform.text}
                </p>
                <div className="text-center xs:text-left">
                  <div onClick={() => scrollTo('contact')} id="signup_platform_demo" className="cursor-pointer inline-block bg-accent2 hover:bg-error active:bg-error-500 rounded uppercase font-bold py-4 px-12 text-white text-xs sm:text-base w-full xxs:w-auto text-center">
                    {props.mlops.platform.btnText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section className="bg-background-blue pt-16 pb-4 " id="SolutionsNew__benefits">
          <div className="container mx-auto">
            <div className="xs:grid xs:grid-cols-7 gap-x-5">
              <div className="col-span-2">
                <h2 className="text-3xl sm:text-6xl mb-16 sm:mb-24 leading-tight sm:leading-tight font-bold text-secondary">
                  {props.mlops.benefits.title}
                </h2>
              </div>
              <div className="col-span-5 sm:grid sm:grid-cols-2 gap-x-5">
                {props.mlops?.benefits?.items.map((item, i) => (
                  <div className="flex mb-12 sm:mb-16 sm:col-span-1">
                    <div className="bg-white rounded-full shrink-0 w-16 h-16 sm:w-20 sm:h-20 relative mr-4 md:mr-8">
                      <img src={item.image?.publicURL} alt="" className="absolute -inset-20 m-auto" />
                    </div>
                    <div className="">
                      <h3 className="text-lg sm:text-2xl mb-4 sm:mb-6 leading-tight sm:leading-tight font-bold text-secondary">
                        {item.title}
                      </h3>
                      <p className="font-normal sm:font-medium">
                        <ReactMarkdown
                          children={item.text}
                          rehypePlugins={[rehypeRaw]}
                        />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Section>
        <section className="bg-secondary pt-16 xs:pt-0 ">
          <div className="xs:grid grid-cols-2 gap-x-6 sm:gap-x-12 xs:items-stretch">
            <div className="text-white xs:order-2 xs:py-6 pb-12 px-4 my-auto">
              <h2 className="text-3xl xs:text-4xl mb-4 sm:mb-6 leading-tight xs:leading-tight font-bold text-white">
                {props.mlops.benefitsTeams.title}
              </h2>
              <ul className="mb-9 sm:text-lg">
                {props.mlops?.benefitsTeams?.items.map((item, i) => (
                  <li className="SolutionsNew__ul__bullets__li mb-3">{item.text}</li>
                ))}
              </ul>
              <div className="text-center">
                <div onClick={() => scrollTo('contact')} id="consultation_2" className="cursor-pointer w-full xxs:w-auto inline-block text-center bg-accent2 hover:bg-error active:bg-error-500 rounded uppercase font-bold py-4 px-3 xxs:px-12 text-white text-xs sm:text-base">
                  {props.mlops.benefitsTeams.btnText}
                </div>
              </div>
            </div>
            <div className="xs:order-1 relative">
              <p className="absolute xs:max-sm:bg-secondary xs:p-2 xs:left-4 xs:bottom-4 xs:right-4 sm:left-1/4 sm:right-5 sm:bottom-5 left-4 bottom-4 right-4 text-white text-sm">
                <sup className="text-2xl ml-[-10px]" style={{ color: "#1fdc6b" }}>*</sup>
                {props.mlops.benefitsTeams.textImage}
              </p>
              <GatsbyImage image={getImage(props.mlops.benefitsTeams.image)} alt="" className="xs:object-cover xs:h-full w-full" />
              <img src={green_lines3} width={260} alt="" className="hidden xs:w-40 xs:block absolute xs:max-sm:-top-10 -left-10 md:-left-4 -bottom-16" />
            </div>
          </div>
        </section>
        <Section id="start5" className="bg-white pt-24 sm:pt-52 pb-20 sm:pb-24">
          <div className="container mx-auto">
            <div className="xs:grid grid-cols-2 gap-x-8 sm:gap-x-16 items-start">
              <div className="text-center xs:text-left">
                <h2 className="text-secondary text-3xl sm:text-5xl leading-tight sm:leading-tight font-bold mb-8 sm:mb-12">
                  {props.mlops.solutions.title}
                </h2>
                <p className="mb-8 sm:mb-12">
                  <ReactMarkdown
                    children={props.mlops.solutions.text}
                    rehypePlugins={[rehypeRaw]}
                  />
                </p>
                <p className="text-secondary text-lg xs:text-xl font-bold mb-6">
                  {props.mlops.solutions.listTitle}
                </p>
                <ul className="mb-9 sm:text-lg text-left">
                  {props.mlops?.solutions?.listItems.map((item, i) => (
                    <li className="SolutionsNew__ul__bullets__li mb-3">
                      <ReactMarkdown
                        children={item.text}
                        rehypePlugins={[rehypeRaw]}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="grid grid-cols-2 xxs:grid-cols-3  sm:mr-8 md:mr-0 sm:grid-cols-4 gap-4 sm:gap-y-0 md:gap-x-6 relative">
                <div className="absolute -left-8 -right-8 -top-16 -bottom-16 hidden sm:block">
                  <img src={how_dotted} alt="" loading="lazy" decoding="async" className="w-full" />
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item1.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item1.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:top-6 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item2.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item2.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item3.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item3.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:top-6 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item4.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item4.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item5.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item5.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:top-6 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item6.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item6.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item7.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">{props.mlops.solutions.items.item7.text}</div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:top-6 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item8.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">
                    {props.mlops.solutions.items.item8.text}
                  </div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item9.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">
                    {props.mlops.solutions.items.item9.text}
                  </div>
                </div>
                <div className="relative bg-white shrink-0 rounded-lg drop-shadow-big z-10 py-6 px-4 sm:top-6 sm:mb-14 md:mb-20">
                  <div className='shrink-0 mb-4'>
                    <img src={props.mlops.solutions.items.item10.image?.publicURL} alt="" loading="lazy" decoding="async" className="h-8 sm:h-12" />
                  </div>
                  <div className="text-xs font-bold">
                    {props.mlops.solutions.items.item10.text}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section className="bg-white relative" id="SolutionsNew__timeline">
          <img src={polyline3} alt="" width="630" loading="lazy" decoding="async" className='hidden sm:block absolute z-10 bottom-16 left-[-25%] -ml-200' />
          <img src={polyline3} alt="" width="530" loading="lazy" decoding="async" className='hidden sm:block absolute z-10 -bottom-14 right-[-30%] -mr-240' />
          <div className="container mx-auto relative z-30">
            <div className="text-center max-w-5xl mx-auto">
              <h2 className="text-secondary text-xl sm:text-5xl leading-tight sm:leading-tight font-bold mb-6 sm:mb-16">
                {props.mlops.timeline.title}
              </h2>
            </div>
            <div className="xs:flex items-center mb-8 xs:mb-16">
              <div className="shrink-0 hidden xs:block"><img src={arrow_blue} alt="" loading="lazy" decoding="async" /></div>
              <div className="grow text-secondary text-center font-bold text-sm xs:text-base flex items-center justify-center bg-background-color2 xs:bg-accent1-lighten p-3 xs:p-2.5">
                <span className="shrink-0">
                  <img src={duration_ico} alt="" width="20" loading="lazy" decoding="async" className='inline-block mr-2' />
                </span>
                Est. project duration: <span className="text-base uppercase xs:text-xl ml-2">6 MONTHS</span>
              </div>
              <div className="shrink-0 hidden xs:block"><img src={arrow_blue_r} alt="" loading="lazy" decoding="async" /></div>
            </div>
            <div className="mb-20 sm:mb-28 overflow-auto sm:overflow-visible -mx-4 px-4 pb-20">
              <div className="flex gap-x-4 sm:grid sm:grid-cols-3">
                <div className="SolutionsNew__learning__block SolutionsNew__learning__block--mobile_visible bg-white w-75 sm:w-auto shrink-0 text-center col-span-1">
                  <div className="drop-shadow-big bg-white relative z-10 p-4 md:py-12 md:px-8 pt-0 overflow-hidden rounded-lg min-h-full flex flex-col">
                    <div className="font-bold bg-primary text-white flex justify-center items-center mb-4 -mx-4 md:absolute md:-left-24 md:-top-24 md:w-48 md:h-48 md:rounded-full md:m-0 md:pt-28 md:pl-28 md:block md:text-left">
                      <span className="text-xs xs:text-sm mr-3 md:block">PHASE</span>
                      <span className="text-2xl xs:text-3xl xs:leading-8 md:pt-1.5 md:block">0</span>
                    </div>
                    <div className="font-bold text-secondary mb-2 text-lg">
                      {props.mlops.timeline.phase0.title}
                    </div>
                    <div className="flex items-center justify-center mb-8">
                      <span className="shrink-0 mr-1">
                        <img src={time_ico} alt="" width="18" loading="lazy" decoding="async" className='inline-block' />
                      </span>
                      <span className="text-greyscale-lightest text-xs mr-2">Est. duration:</span>
                      <span className="text-secondary uppercase xs:text-sm font-bold">
                        {props.mlops.timeline.phase0.duration}
                      </span>
                    </div>
                    <div className="text-greyscale-lighten mb-8 ">
                      <ReactMarkdown
                        children={props.mlops.timeline.phase0.text}
                        rehypePlugins={[rehypeRaw]}
                      />
                    </div>
                    <ul className='text-left font-normal mb-8 text-greyscale-lighten'>
                      {props.mlops?.timeline?.phase0.list.map((item, i) => (
                        <li className='SolutionsNew__ul__bullets__li SolutionsNew__ul__bullets__li--disc mb-1.5'>
                          {item.text}
                        </li>
                      ))}
                    </ul>
                    <div className="bg-accent1-lightest rounded-md p-4 text-left min-h-[190px]">
                      <div className="font-bold mb-4 text-secondary">
                        {props.mlops.timeline.phase0.bottom.title}
                      </div>
                      <ul className='text-sm font-normal'>
                        {props.mlops?.timeline?.phase0.bottom.list.map((item, i) => (
                          <li className='SolutionsNew__ul__bullets__li SolutionsNew__ul__bullets__li--checked mb-1.5'>
                            {item.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="SolutionsNew__learning__block SolutionsNew__learning__block--mobile_visible bg-white w-75 sm:w-auto shrink-0 text-center col-span-1">
                  <div className="drop-shadow-big bg-white relative z-10 p-4 md:py-12 md:px-8 pt-0 overflow-hidden rounded-lg min-h-full flex flex-col">
                    <div className="font-bold bg-primary text-white flex justify-center items-center mb-4 -mx-4 md:absolute md:-left-24 md:-top-24 md:w-48 md:h-48 md:rounded-full md:m-0 md:pt-28 md:pl-28 md:block md:text-left">
                      <span className="text-xs xs:text-sm mr-3 md:block">PHASE</span>
                      <span className="text-2xl xs:text-3xl xs:leading-8 md:pt-1.5 md:block">1</span>
                    </div>
                    <div className="font-bold text-secondary mb-2 text-lg">
                      {props.mlops.timeline.phase1.title}
                    </div>
                    <div className="flex items-center justify-center mb-8">
                      <span className="shrink-0 mr-1">
                        <img src={time_ico} alt="" width="18" loading="lazy" decoding="async" className='inline-block' />
                      </span>
                      <span className="text-greyscale-lightest text-xs mr-2">Est. duration:</span>
                      <span className="text-secondary uppercase xs:text-sm font-bold">
                        {props.mlops.timeline.phase1.duration}
                      </span>
                    </div>
                    <div className="text-greyscale-lighten mb-8">
                      <ReactMarkdown
                        children={props.mlops.timeline.phase1.text}
                        rehypePlugins={[rehypeRaw]}
                      />
                    </div>
                    <ul className='text-left font-normal mb-8 grow  text-greyscale-lighten'>
                      {props.mlops?.timeline?.phase1.list.map((item, i) => (
                        <li className='SolutionsNew__ul__bullets__li SolutionsNew__ul__bullets__li--disc mb-1.5'>
                          {item.text}
                        </li>
                      ))}
                    </ul>
                    <div className="bg-accent1-lightest rounded-md p-4 text-left min-h-[190px]">
                      <div className="font-bold mb-4 text-secondary">Deliverables</div>
                      <ul className='text-sm font-normal'>
                        {props.mlops?.timeline?.phase1.bottom.list.map((item, i) => (
                          <li className='SolutionsNew__ul__bullets__li SolutionsNew__ul__bullets__li--checked mb-1.5'>
                            {item.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="SolutionsNew__learning__block SolutionsNew__learning__block--mobile_visible bg-white w-75 sm:w-auto shrink-0 text-center col-span-1">
                  <div className="drop-shadow-big bg-white relative z-10 p-4 md:py-12 md:px-8 pt-0 overflow-hidden rounded-lg min-h-full flex flex-col">
                    <div className="font-bold bg-primary text-white flex justify-center items-center mb-4 -mx-4 md:absolute md:-left-24 md:-top-24 md:w-48 md:h-48 md:rounded-full md:m-0 md:pt-28 md:pl-28 md:block md:text-left">
                      <span className="text-xs xs:text-sm mr-3 md:block">PHASE</span>
                      <span className="text-2xl xs:text-3xl xs:leading-8 md:pt-1.5 md:block">2</span>
                    </div>
                    <div className="font-bold text-secondary mb-2 text-lg">
                      {props.mlops.timeline.phase2.title}
                    </div>
                    <div className="flex items-center justify-center mb-8">
                      <span className="shrink-0 mr-1">
                        <img src={time_ico} alt="" width="18" loading="lazy" decoding="async" className='inline-block' />
                      </span>
                      <span className="text-greyscale-lightest text-xs mr-2">Est. duration:</span>
                      <span className="text-secondary uppercase xs:text-sm font-bold">
                        {props.mlops.timeline.phase2.duration}
                      </span>
                    </div>
                    <div className="text-greyscale-lighten mb-8">
                      <ReactMarkdown
                        children={props.mlops.timeline.phase2.text}
                        rehypePlugins={[rehypeRaw]}
                      />
                    </div>
                    <ul className='text-left font-normal mb-8 grow text-greyscale-lighten'>
                      {props.mlops?.timeline?.phase2.list.map((item, i) => (
                        <li className='SolutionsNew__ul__bullets__li SolutionsNew__ul__bullets__li--disc mb-1.5'>
                          {item.text}
                        </li>
                      ))}
                    </ul>
                    <div className="bg-accent1-lightest rounded-md p-4 text-left min-h-[190px]">
                      <div className="font-bold mb-4 text-secondary">Deliverables</div>
                      <ul className='text-sm font-normal'>
                        {props.mlops?.timeline?.phase2.bottom.list.map((item, i) => (
                          <li className='SolutionsNew__ul__bullets__li SolutionsNew__ul__bullets__li--checked mb-1.5'>
                            {item.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-4 sm:hidden'>&nbsp;</div>
              </div>
            </div>
          </div>
        </Section>
        <Section id="start7" className="bg-secondary py-14 relative">
          <img src={free_bg} alt="" width="450" height="450" loading="lazy" decoding="async" className='absolute -top-full -bottom-full my-auto SolutionsNew__flip__h max-w-none md:w-[750px] -left-56 xs:-left-44 sm:-left-24 md:-left-32 z-0' />
          <img src={free_bg} alt="" width="450" height="450" loading="lazy" decoding="async" className='absolute -top-full -bottom-full my-auto max-w-none md:w-[750px] -right-56 xs:-right-44 sm:-right-24 md:-right-32 z-0' />
          <div className="container mx-auto relative z-10">
            <div className="text-center leading-relaxed max-w-xs xs:max-w-none mx-auto">
              <p className="mb-2 text-white sm:text-2xl">
                {props.mlops.ctaB.subtitle}
              </p>
              <h2 className="text-white text-lg sm:text-3xl font-bold mb-9">
                {props.mlops.ctaB.title}
              </h2>
              <div className="text-center">
                <div id="solution_mlops_schedule_2_btn" onClick={() => scrollTo('contact')} className="cursor-pointer w-full xxs:w-auto inline-block bg-accent2 hover:bg-error active:bg-error-500 rounded uppercase font-bold py-4 px-4 xxs:px-12 text-white text-xs sm:text-base">
                  {props.mlops.ctaB.btnText}
                </div>
              </div>
            </div>
          </div>
        </Section>

        <Section id="SolutionsNew__base">
          <div>
            <section className="bg-background-blue pt-16 sm:pt-28 relative z-20">
              <img src={polyline1} alt="" width="630" loading="lazy" decoding="async" className='absolute -left-64 top-14 z-0 hidden md:block' />
              <img src={polyline2} alt="" width="630" loading="lazy" decoding="async" className='absolute -right-16 bottom-0 -mb-24 z-0 hidden md:block' />
              <div className="container SolutionsNew__videos__swiper mx-auto relative z-10">
                <div className="text-center max-w-2xl mx-auto">
                  <h2 className="text-secondary text-3xl sm:text-5xl leading-tight sm:leading-tight font-bold mb-8 sm:mb-16">
                    {props.mlops.videos.title}
                  </h2>
                </div>
                <Swiper
                  loop={true}
                  navigation={true}
                  modules={[Navigation, Pagination]}
                  pagination={{ clickable: true }}

                  breakpoints={{
                    // when window width is >= 640px
                    220: {
                      spaceBetween: 8
                    },
                    860: {
                      spaceBetween: 32
                    },
                    1100: {
                      spaceBetween: 64
                    }
                  }}
                  className="swiper__default"
                >
                  {props.mlops.videos?.items.map((item, i) => (
                    <SwiperSlide>
                      <div className="SolutionsNew__videos__iframe SolutionsNew__learning__block">
                        <VideoModalPreview
                          videoID={item.idVideo}
                          channel={'youtube'}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </section>
            <section className="bg-white pb-24 sm:pb-32 relative z-10">
              <img src={waves_grey} alt="" loading="lazy" decoding="async" className="block w-full relative z-0" />
              <div className="container mx-auto">
                <div className="text-center max-w-2xl mx-auto">
                  <h2 className="text-secondary text-3xl sm:text-5xl leading-tight sm:leading-tight font-bold mb-12 sm:mb-16">
                    {props.mlops.blogs.title}
                  </h2>
                </div>
              </div>
              <Swiper
                loop={true}
                spaceBetween={8}
                navigation={true}
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                breakpoints={{
                  // when window width is >= 640px
                  220: {
                    slidesPerView: 1,
                    spaceBetween: 8
                  },
                  579: {
                    slidesPerView: 2
                  },
                  860: {
                    slidesPerView: 2,
                    spaceBetween: 32
                  },
                  1160: {
                    slidesPerView: 3,
                    spaceBetween: 32
                  },
                  1601: {
                    slidesPerView: 4,
                    spaceBetween: 32
                  },
                }}
                className="swiper__blog swiper__default">
                {blogsFiltered.map(({ title, image, excerpt, slug, category, author, date }, i) => (
                  <SwiperSlide key={i} className="SolutionsNew__learning__block SolutionsNew__learning__block--mobile_visible bg-white text-center sm:text-left">
                    <div className="drop-shadow-big bg-white relative overflow-hidden rounded-lg min-h-full flex flex-col justify-center p-4 pt-0">
                      <div className="SolutionsNew__blog__img mb-6 xs:mb-8 -mx-4">
                        <GatsbyImage image={getImage(image)} alt={title} className='h-[200px] sm:h-[300px]' />
                        {category &&
                          <div className="SolutionsNew__blog__img__tag">
                            {DataBlogCategories.items.find(x => x.id === category)?.name}
                          </div>
                        }
                      </div>
                      <div className="grow">
                        <div className="flex flex-col sm:flex-row items-center mb-3 justify-center sm:justify-start">
                          <div className="flex mb-1 items-center sm:mr-4">
                            <div className="shrink-0 mr-2">
                              <img src={calendar} alt="" width={16} loading="lazy" decoding="async" className='' />
                            </div>
                            <span className="text-xs text-secondary">{formatDate(date)}</span>
                          </div>
                          <div className="flex mb-1">
                            <div className="shrink-0 mr-2">
                              <img src={imgAuthor} alt="" width={16} loading="lazy" decoding="async" className='' />
                            </div>
                            <span className="text-xs text-secondary">
                              {author.map((e) => e.label).join(', ')}
                            </span>
                          </div>
                        </div>
                        <h3 className="font-bold text-secondary text-2xl mb-4">
                          {title}
                        </h3>
                        <div className="text-greyscale-lighten font-normal mb-6">
                          {excerpt}
                        </div>
                      </div>
                      <Link className='uppercase text-primary flex items-center justify-center sm:justify-start' to={slug}>
                        <span className="mr-2 font-bold">READ MORE</span>
                        <span className='shrink-0'>
                          <img src={arrow_more} alt="" width={12} loading="lazy" decoding="async" className='' />
                        </span>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </div>
        </Section>

        <div className="flex flex-col">
          <section className="bg-secondary py-24 sm:mb-24 order-2 sm:order-1">
            <div className="container mx-auto">
              <div className="sm:grid sm:grid-cols-6 gap-x-8 sm:gap-x-16">
                <div className="mb-16 col-span-2 sm:col-span-2">
                  <div className="block mb-8">
                    <div className="shrink-0 mr-6 mb-6 max-w-[200px]">
                      <img src={props.mlops.knowledgeBase.logo?.publicURL} alt="" />
                    </div>
                    <h2 className="text-primary text-3xl font-bold">
                      {props.mlops.knowledgeBase.title}
                    </h2>
                  </div>
                  <p className="text-white">
                    {props.mlops.knowledgeBase.text}
                  </p>
                </div>
                <div className='sm:col-span-4'>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {props.mlops.knowledgeBase?.items.map((item, i) => (
                      <div className="SolutionsNew__pluginsItem bg-background-blue p-8 rounded-lg sm:hover:before:bg-white before:rounded-lg group/plugin drop-shadow-big hover:z-20">
                        <div className="flex flex-col justify-center items-center sm:items-start">
                          <div className="sm:flex items-center grow relative z-30">
                            <div className="shrink-0 mb-4 sm:mb-0 sm:mr-4">
                              <img src={item.icon?.publicURL} alt="" className='mx-auto' />
                            </div>
                            <h3 className="text-2xl text-secondary font-bold mb-6 sm:mb-0">
                              {item.title}
                            </h3>
                          </div>
                          <a href={item.link} className="sm:group-hover/plugin:inline-block font-bold text-xs text-white py-3 px-6 block text-center bg-primary hover:bg-primary-darken active:bg-primary-lighten rounded uppercase sm:mt-12 z-30 sm:hidden sm:absolute sm:left-8 sm:-bottom-8">Get plugin on GitHub</a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white pb-24 order-1 sm:order-2">
            <div className="container mx-auto">
              <div className="sm:grid grid-cols-4 gap-x-4 items-center">
                <div className="col-span-2">
                  <h2 className="text-lg xs:text-3xl mb-12 sm:mb-0 text-center sm:text-left text-secondary font-bold">
                    {props.mlops.partners.title}
                  </h2>
                </div>
                <div className="col-span-2">
                  <div className="flex flex-wrap -mx-4 justify-center items-center">
                    {props.mlops.partners?.logos.map((item, i) => (
                      <div className="shrink-0 mx-4">
                        <img src={item.image?.publicURL} loading="lazy" decoding="async" alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </ScrollingProvider >
  )
};

const SolutionsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const mergedData = {
    ...frontmatter,
    blogs: data.blogs.edges.map(({ node }) => node),
  };

  return (
    <Layout
      variants={['backgrounded']}
      meta={frontmatter.meta}
      contactTitle="Ready to build YOUⓇ MLOps Platform?"
      contactText="Please fill out the form and we will come back to you as soon as possible to schedule a meeting to discuss about your event processing needs."
    >
      <SolutionsPageTemplate {...mergedData} />
    </Layout>
  );
};

SolutionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SolutionsPage;

export const SolutionsPageQuery = graphql`query SolutionsPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "solutions-new-page"}}) {
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      hero {
        title
        subtitle
        items {
          text
        }
        subtext
      }
      clients {
        title
        items {
          image {
            publicURL
            name
          }
          title
          text
          btnLink
          btnText
        }
      }
      challenge {
        title
        subtitle
        text
        items {
          title
          text
          image {
            publicURL
            name
          }
        }
      }
      ctaA {
        subtitle
        title
        btnText
      }
      mlops {
        intro {
          title
          text
          subtext
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
        cta {
          subtitle
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          btnLink
          btnText
        }
        main {
          title
          textA
          textB
          subText
          items {
            image {
              publicURL
              name
            }
          }
        }
        opinion {
          image {
            publicURL
            name
          }
          text
          authorName
          authorTitle
        }
        graph {
          title
          text
          colA {
            subtext
            title
            text
          }
          colB {
            subtext
            title
            text
          }
          colC {
            subtext
            title
            text
          }
        }
        principles {
          title
          text
          items {
            text
            image {
              publicURL
              name
            }
          }
        }
        platform {
          title
          text
          btnText
        }
        benefits {
          title
          items {
            title
            text
            image {
              publicURL
              name
            }
          }
        }
        benefitsTeams {
          title
          btnText
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          textImage
          items {
            text
          }
        }
        solutions {
          title
          text
          listTitle
          listItems {
            text
          }
          items {
            item1 {
              text
              image {
                publicURL
                name
              }
            }
            item2 {
              text
              image {
                publicURL
                name
              }
            }
            item3 {
              text
              image {
                publicURL
                name
              }
            }
            item4 {
              text
              image {
                publicURL
                name
              }
            }
            item5 {
              text
              image {
                publicURL
                name
              }
            }
            item6 {
              text
              image {
                publicURL
                name
              }
            }
            item7 {
              text
              image {
                publicURL
                name
              }
            }
            item8 {
              text
              image {
                publicURL
                name
              }
            }
            item9 {
              text
              image {
                publicURL
                name
              }
            }
            item10 {
              text
              image {
                publicURL
                name
              }
            }
          }
        }
        timeline {
          title
          duration {
            title
            text
          }
          phase0 {
            title
            duration
            text
            list {
              text
            }
            bottom {
              title
              list {
                text
              }
            }
          }
          phase1 {
            title
            duration
            text
            list {
              text
            }
            bottom {
              title
              list {
                text
              }
            }
          }
          phase2 {
            title
            duration
            text
            list {
              text
            }
            bottom {
              title
              list {
                text
              }
            }
          }
        }
        ctaB {
          subtitle
          title
          btnText
        }
        videos {
          title
          items {
            idVideo
          }
        }
        blogs {
          title
        }
        knowledgeBase {
          logo {
            publicURL
            name
          }
          title
          text
          items {
            title
            icon {
              publicURL
              name
            }
            link
          }
        }
        partners {
          title
          logos {
            image {
              publicURL
              name
            }
          }
        }
      }
    }
  }
  blogs: allMarkdownRemark(
    sort: {frontmatter: {date: DESC}}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 200)
        id
        fields {
          slug
        }
        html
        frontmatter {
          order
          title
          description
          slug
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          category
          author {
            label
            value
          }
          tags {
            label
            value
          }
          date
        }
      }
    }
  }
}`;

import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import ModalVideo from 'react-modal-video';
import classNames from 'classnames';
import { Portal } from 'react-portal';

import IconPlay from '../../static/img/icon-play.svg';

function VideoModalPreview({ className, videoID, channel, url_mp4, url_webm, image }) {

  const [isToggled, setToggled] = useState(false);
  const videoExist = ((url_mp4 && url_mp4.publicURL.length > 0) || (url_webm && url_webm.publicURL.length > 0))

  let Img = null;
  if (image?.length) {
    Img = <img src={image} alt="" />;
  }

  if (!videoExist && !image && channel == 'youtube') {
    Img = <img
      className="VideoModalPreview__item__imgYoutube"
      src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
      alt=""
    />;
  }

  return (
    <div className={classNames(className, 'VideoModalPreview')}>
      <div className="VideoModalPreview__item" onClick={() => setToggled(!isToggled)}>
        {videoExist &&
          <video muted="muted" loop="loop" autoPlay="autoplay" poster="">
            {url_mp4.publicURL.length > 0 &&
              <source src={url_mp4.publicURL} type="video/mp4" />
            }
            {url_webm.publicURL.length > 0 &&
              <source src={url_webm.publicURL} type="video/webm" />
            }
            Your browser does not support the video tag
          </video>
        }

        {Img}

        <SVG
          src={IconPlay}
          uniquifyIDs={false}
          className="VideoModalPreview__item__btn"
        />
      </div>
      {videoID &&
        <Portal>
          <ModalVideo
            channel={channel && channel.length ? channel : 'youtube'}
            isOpen={isToggled}
            videoId={videoID}
            onClose={() => setToggled(false)}
          />
        </Portal>
      }
    </div>
  );
}

export default VideoModalPreview;
